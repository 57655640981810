body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'StardosStencil';
  src: url(./fonts/StardosStencil-Bold.ttf);
}
@font-face {
  font-family: 'Roboto';
  src: url(./fonts/Roboto-Regular.ttf);
}
@font-face {
  font-family: 'AbyssinicaSIL';
  src: url(./fonts/AbyssinicaSIL-Regular.ttf);
}
@font-face {
  font-family: 'Msyi';
  src: url(./fonts/msyi.ttf);
}


/*********************************** Bidder Notifier Popup *********************************/
.show-notifier-popup {
  transform:scale(1);
  -webkit-transform:scale(1);
  transition:opacity 0.5s,transform 0.5s;
  -webkit-transition:opacity 0.5s, transform 0.5s;
  left: 0;
  transition: 500ms;
  width: 270px;
  z-index: 10;
}
.hide-notifier-popup {
  transform:scale(0);
  -webkit-transform:scale(0);
  transition:opacity 0.5s,transform 0.5s;
  -webkit-transition:opacity 0.5s, transform 0.5s;
  left: -100%;
  transition: 500ms;
  width: 270px;
}


/*********************************** Language Dropdown *************************************/
.dropdown-menu {
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
}
.dropdown:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
}


/******************************* Home Grid cards Swing ************************************/
.hang-cards {
  animation: hang 2.5s linear ;
  animation-iteration-count: 1;
  transform-origin: right top;
}
@keyframes hang {
  0%, 80%, 90%, 100% {
    transform: rotate(0);
  }
  8%, 10%, 20% {
    transform: rotate(-40deg);
  }
  15%, 25% {
    transform: rotate(-25deg);
  }
  30%, 50%, 60%, 70%, 75% {
    transform: rotate(-30deg);
  }
  85%, 95% {
    transform: rotate(-5deg);
  }
}


/******************************* Notification bell icon & top-ten bidders cup ************************************/
.has-notification {
  animation: bell 3s ease-out infinite;
  animation-iteration-count: 10;
  transform-origin: center top;
}
.top-bider-cup {
  animation: bell 6s ease-out infinite;
  transform-origin: center center;
}
@keyframes bell {
  0%, 90% {
    transform: rotateZ(0);
  }
  5%, 15%, 25%, 35%, 45%, 55%, 65%, 75% {
    transform: rotateZ(30deg);
  }
  10%, 20%, 30%, 40%, 50%, 60%, 70%, 80% {
    transform: rotateZ(-30deg);
  }
}


/************************ Remove Arrows From input type=number ***************/
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


/**************************** Top bidder cup ***************************/
.top-bidder {
  transform: scale(1);
  animation: pop 1.5s infinite;
}
@keyframes pop {
	0%, 100% {
		transform: scale(1);
	}
	50% {
		transform: scale(1.2);
	}
}


/*************************** Auction about to end notifier *************************/
.blob {
	background: red;
	border-radius: 50%;
	height: 16px;
	width: 16px;
	box-shadow: 0 0 0 0 rgb(193, 17, 17);
	transform: scale(1);
	animation: blink 1s infinite;
}
@keyframes blink {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(214, 31, 31, 0.7);
	}
	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
	}
	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}
.animate-bounce-horizontal {
  animation: bounce 1s infinite;
}
@keyframes bounce {
  0%, 100% {
    transform: translateX(-5%);
    animation-timing-function: cubic-bezier(0.8,0,1,1);
  }
  50% {
    transform: none;
    animation-timing-function: cubic-bezier(.48,.57,.83,.67);
  }
}


/*********************************** Home Slider text animation **********************/
.show-text {
  transform: translateY(0%);
  transition: 2s ease;
  height: 80px;
}
.hide-text {
  transform: translateY(100%);
  transition: 0.1s ease;
  height: 0;
  overflow: hidden;
}


/************************************* Modal animation *******************************/
.modal-close {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.4s cubic-bezier(0.4, 0, 0.2, 1);
}
.modal-open {
  overflow: hidden;
  height: auto;
  max-height: 100%;
  background-color: rgb(0 0 0 / 0.5);
  transition: max-height 0.4s cubic-bezier(0.4, 0, 0.2, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1) 0.4s;
}


/************************************* Image Upload Box *******************************/
.hide-button {
  max-height: 0;
  overflow: hidden;
  bottom: 0px;
  transform: translateY(100%);
  transition: 0.3s ease;
}
.show-button {
  overflow: hidden;
  height: 32px;
  max-height: 9999px;
  transform: translateY(0%);
  transition: 0.3s ease;
}


/************************************** News & tenders title ***********************/
.scroll-title {
  -moz-transform: translateX(100%);
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  -moz-animation: my-animation 15s linear infinite;
  -webkit-animation: my-animation 15s linear infinite;
  animation: my-animation 15s linear infinite;
}
/* for Firefox */
@-moz-keyframes my-animation {
  from { -moz-transform: translateX(100%); }
  to { -moz-transform: translateX(-100%); }
}
/* for Chrome */
@-webkit-keyframes my-animation {
  from { -webkit-transform: translateX(100%); }
  to { -webkit-transform: translateX(-100%); }
}
@keyframes my-animation {
  from {
    -moz-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  to {
    -moz-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}


/************************************** SideBar ************************************/
 .burger {
  height: 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.side-bar-nav {
  background: white;
  width: 270px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  transition: 350ms;
  z-index: 10;
}
.side-bar {
  left: 0;
}
.not-side-bar {
  left: -100%;
}
.nav-link {
  font-size: 2rem;
  height: 80px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.sidebar-link {
  height: 40px;
  padding-left: 2rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: rgb(103, 100, 100);
  font-size: 14px;
}
.sidebar-link-active {
  height: 40px;
  padding-left: 2rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  font-size: 16px;
  color: #027ABF
}
.sidebar-link:hover {
  background: hsl(209, 98%, 48%);
  cursor: pointer;
  color: white;
}
.sidebar-label {
  margin-left: 15px;
}
.dropdown-link {
  background: white;
  height: 30px;
  padding-left: 3rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: gray;
  font-size: 14px;
}
.dropdown-link:hover {
  background: #632ce4;
  cursor: pointer;
}


/************************************** News & Tenders toggle *********************/
.switch-button {
  background: #8e8782;
  overflow: hidden;
  width: 250px;
  text-align: center;
  font-size: 16px;
  letter-spacing: 1px;
  color: #fff;
  position: relative;
  padding-right: 120px;
}
.switch-button:before {
  /* content: "Tenders"; */
  content: attr(lablename);
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
  pointer-events: none;
}
.switch-button-checkbox {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 2;
}
.switch-button-checkbox:checked + .switch-button-label:before {
  transform: translateX(120px);
  transition: transform 300ms linear;
}
.switch-button-checkbox + .switch-button-label {
  position: relative;
  padding: 4px;
  display: block;
  user-select: none;
  pointer-events: none;
}
.switch-button-checkbox + .switch-button-label:before {
  content: "";
  background: rgb(2 132 199);
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  transform: translateX(0);
  transition: transform 300ms;
}
.switch-button-checkbox + .switch-button-label .switch-button-label-span {
  position: relative;
}


/******************** Mobile ViewBy Toggle *************/
.mob-switch-button {
  background: rgba(151, 149, 149, 0.56);
  overflow: hidden;
  width: 110px;
  text-align: center;
  font-size: 14px;
  color: #fff;
  position: relative;
  padding-right: 40px;
}
.mob-switch-button:before {
  /* content: "my"; */
  content: attr(mylist);
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
  pointer-events: none;
  font-size: 14px;
}
.mob-switch-button-checkbox {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 2;
}
.mob-switch-button-checkbox:checked + .mob-switch-button-label:before {
  transform: translateX(55px);
  transition: transform 300ms linear;
}
.mob-switch-button-checkbox + .mob-switch-button-label {
  position: relative;
  /* padding: 4px; */
  display: block;
  user-select: none;
  pointer-events: none;
}
.mob-switch-button-checkbox + .mob-switch-button-label:before {
  content: "";
  background: #1967cd;
  height: 100%;
  width: 80%;
  position: absolute;
  left: 0;
  top: 0;
  padding-top: 1;
  transform: translateX(0);
  transition: transform 300ms;
}
.mob-switch-button-checkbox + .mob-switch-button-label .mob-switch-button-label-span {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  margin-bottom: 30px;
}


/************************************** Chat Popup *********************************/
.open-button {
  color: white;
  padding: 8px 10px;
  border: none;
  cursor: pointer;
  opacity: 0.8;
  position: fixed;
  bottom: 0;
  right: 20px;
  width: 200px;
}
.chat-popup {
  position: fixed;
  bottom: 0;
  right: 15px;
  border: 0.5px solid #b5b0b0;
  z-index: 9;
  transition: 350ms;
  overflow: hidden;
}
.form-container {
  max-width: 300px;
  padding: 8px;
  background-color: white;
}
.form-container textarea {
  width: 100%;
  padding: 15px;
  margin: 5px 0 22px 0;
  border: none;
  background: #f1f1f1;
  resize: none;
  min-height: 200px;
}
.form-container textarea:focus {
  background-color: rgb(231 229 228);
  outline: none;
}
.form-container .btn {
  background-color: rgb(2 132 199);
  color: white;
  padding: 8px 10px;
  border: none;
  cursor: pointer;
  width: 100%;
  margin-bottom:5px;
  opacity: 0.8;
}
.form-container .cancel {
  background-color: rgb(112, 85, 85);
}
.form-container .btn:hover, .open-button:hover {
  opacity: 1;
}
.chat-open {
  bottom: 0;
}
.chat-close {
  bottom: -100%;
}


/************************************** Scrollbar ****************/
::-webkit-scrollbar {
  width: 10px;
  background: white;
}
::-webkit-scrollbar-track {
  margin-top: 104px;
  background-color: rgba(245, 245, 244, 1);
}
::-webkit-scrollbar-thumb {
  background-color: rgba(88, 92, 92, 0.2);
}
.others::-webkit-scrollbar-track {
  margin-top:60px;
}
.chat-thread::-webkit-scrollbar-track {
  margin-top:0px;
}
.notification::-webkit-scrollbar {
  background: transparent;
}
.notification::-webkit-scrollbar-track {
  margin-bottom: 5px;
  border-bottom-right-radius: 20px;
}
.notification::-webkit-scrollbar-thumb {
  border-bottom-right-radius: 20px;
}
.news-scroll::-webkit-scrollbar {
  background: transparent;
}
.bid-security::-webkit-scrollbar {
  background: transparent;
}
.bid-security::-webkit-scrollbar-thumb {
  border-bottom-right-radius: 20px;
}
.bid-security::-webkit-scrollbar-track {
  margin-bottom: 5px;
  border-bottom-right-radius: 20px;
}
.map-info-window::-webkit-scrollbar {
  background: transparent;
}
.summary::-webkit-scrollbar {
  background: transparent;
}
.summary::-webkit-scrollbar-track {
  background: transparent;
}
.summary-ended::-webkit-scrollbar {
  background-color: rgb(0 0 0 / 0.01);
}
.summary-ended::-webkit-scrollbar-track {
  background-color: rgb(0 0 0 / 0.01);
}
.spec::-webkit-scrollbar-track {
  margin-top: 0;
}


/************************************** Chat Box ****************/
.chat-thread {
  margin: 24px auto 0 auto;
  padding: 0 20px 0 0;
  list-style: none;
  overflow-y: scroll;
  overflow-x: hidden;
}
.msg {
  position: relative;
  clear: both;
  display: inline-block;
  padding: 12px 30px 12px 20px;
  margin: 0 0 20px 0;
  font: 14px/20px "Noto Sans", sans-serif;
  border-radius: 10px;
  background-color: rgba(25, 147, 147, 0.106);
}
/* Chat - Avatar */
.msg:before {
  position: absolute;
  top: 0;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  content: "";
}
/* Chat - Speech Bubble Arrow */
.msg:after {
  position: absolute;
  top: 15px;
  content: "";
  width: 0;
  height: 0;
  border-top: 15px solid rgba(25, 147, 147, 0.106);
}
.me {
  animation: show-chat-me 0.15s 1 ease-in;
  -moz-animation: show-chat-me 0.15s 1 ease-in;
  -webkit-animation: show-chat-me 0.15s 1 ease-in;
  float: right;
  margin-right: 30px;
  color: #17615a;
  background-color: #e0e6f9;
}
.me:after {
  border-right: 15px solid transparent;
  right: -15px;
  border-top: 15px solid #e0e6f9;
}
.friend {
  animation: show-chat-friend 0.15s 1 ease-in;
  -moz-animation: show-chat-friend 0.15s 1 ease-in;
  -webkit-animation: show-chat-friend 0.15s 1 ease-in;
  float: left;
  margin-left: 80px;
  color: #4b5563;
}
.friend:before {
  left: -80px;
  background-image: url(./assets/logo192.png);
  height: 50px;
  width: 50px;
  background-size: 100% 100%;
}
.friend:after {
  border-left: 15px solid transparent;
  left: -15px;
  border-top: 15px solid rgba(25, 147, 147, 0.106);    
}
/* Small screens */
@media all and (max-width: 767px) {
  .chat-thread {
    width: 92%;
    height: 63vh;
  }
}
/* Medium and large screens */
@media all and (min-width: 768px) {
  .chat-thread {
    width: 70%;
    height: 490px;
  }
}
@keyframes show-chat-friend {
  0% {
    margin-left: -480px;
  }
  100% {
    margin-left: 0;
  }
}
@-moz-keyframes show-chat-friend {
  0% {
    margin-left: -480px;
  }
  100% {
    margin-left: 0;
  }
}
@-webkit-keyframes show-chat-friend {
  0% {
    margin-left: -480px;
  }
  100% {
    margin-left: 0;
  }
}
@keyframes show-chat-me {
  0% {
    margin-right: -480px;
  }
  100% {
    margin-right: 0;
  }
}
@-moz-keyframes show-chat-me {
  0% {
    margin-right: -480px;
  }
  100% {
    margin-right: 0;
  }
}
@-webkit-keyframes show-chat-me {
  0% {
    margin-right: -480px;
  }
  100% {
    margin-right: 0;
  }
}


/************************************** Image counter and footer background ************************************/
.img-count-bg {
  background-color: 	#00000060 ;
} 
.footer {
  background-color: #2e2f31;
}


/********************************************** FAQ Accordion **************************/
.wrapper {
  display: flex;
  height: auto;
  justify-content: center;
  align-items: flex-start;
}
.accordion {
  width: 100%;
}
.item {
  background-color: white;
  margin-bottom: 5px;
}
.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding-left: 8px;
  padding-right: 8px;
}
.content {
  color: gray;
  padding-left: 8px;
  padding-right: 8px;
  max-height: 0;
  overflow: hidden;
  transition: all 0.5s cubic-bezier(0,1,0,1);
}
.content.show {
  height: auto;
  max-height: 9999px;
  transition: all 0.5s cubic-bezier(1,0,1,0)
}


/************************************* Spinner *****************************/
.spinner {
  border: 2px solid;
  border-radius: 50%;
  border-top-color: transparent;
  border-bottom-color: transparent;
  animation: spin 1s linear infinite;
}
@keyframes spin {
  to {
    transform:  rotate(360deg)
  }
}


/************************************* Mapping *****************************/
/* .mapping {width: 48vw; height: 350px;} */
@media (max-width: 767px) {
  /* .mapping {width: 100%; height: 200px;} */
  .mapping {width: 100%; height: 400px;}
}
@media (min-width: 768px) {
  /* .mapping {width: 100%; height: 330px;} */
  .mapping {width: 100%; height: 600px;}
}


/************************************* Image Gallery Slider ************************/
@import "~react-image-gallery/styles/css/image-gallery.css";
.gallery_container { height: 427px; }
.image-gallery-left-nav .image-gallery-svg,.image-gallery-right-nav .image-gallery-svg { height: 50px; width: 25px; }
.image-gallery-thumbnail.active,.image-gallery-thumbnail:focus { width: 95px; outline: none; border: 3px solid rgb(91, 199, 2); }
.image-gallery-fullscreen-button .image-gallery-svg,.image-gallery-play-button .image-gallery-svg { height: 20px; width: 20px; }
.image-gallery-index { font-size: .8em; padding: 5px 8px }
.image-gallery-thumbnails-container { width: 96px; }
.image-gallery-thumbnail { width: 96px; }
.image_gallery { background-color: black; height: 100%; }
.image-gallery-swipe { height: inherit !important; }
.image-gallery-image { height: 100%; object-fit: cover; }
.image-gallery-slides { width: 100%; height: 100%; }
.image-gallery-slide { object-fit: contain; height: 100%; width: 100%; }
.image-gallery-slide-wrapper { height: 100%; }
.image-gallery-content { height: 100%; }

/* Mobile */
@media only screen and (max-width: 767px) {
  .gallery_container { width: 100%; height: 200px; }
  .image-gallery-left-nav .image-gallery-svg,.image-gallery-right-nav .image-gallery-svg { display: none; }
  .image-gallery-index { font-size:.6em; padding:5px 8px; }
  .image-gallery-fullscreen-button .image-gallery-svg,.image-gallery-play-button .image-gallery-svg{ height:18px; width:18px; }
  .image-gallery-thumbnails-container { width: 80px; }
  .image-gallery-thumbnail { width: 80px; }
  .image-gallery-thumbnail.active,.image-gallery-thumbnail:focus{outline:none;border:2px solid rgb(91, 199, 2); width: 79px;}
}

/* Tablet */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  /* Styles for tablet devices */
}

/* Small Screen Laptop */
@media only screen and (min-width: 1024px) and (max-width: 1279px) {
  /* Styles for small screen laptops */
}


/************************************** Home page Image Slider ************************/
.slider-root {
  width: 100%;
  height: 100%;
  background-color: #eee;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
}
.slider-container {
  display: flex;
  width: 100%;
  height: 100%;
  list-style: none;
  padding: 0;
  margin: 0;
}
.slider-slide {
  flex-shrink: 0;
  width: 100%;
  height: 100%;
}


/************************************** Shake Login Error Message *********************/
.shake-text {
  animation: shake 1s infinite;
}
@keyframes shake {
  0%, 100% {transform: translateX(0);}
  10%, 30%, 50%, 70%, 90% {transform: translateX(-6px);}
  20%, 40%, 60%, 80% {transform: translateX(6px);}
}


/*********************************************** My-Bids Side Popup **********************************/
.side-popup {
  right: 4;
  transition: 2s;
}
.not-side-popup {
  right: -100%;
  transition: 2s;
}


/************************************************** End Time Countdown Timer *****************************/
.countdown-timer {
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  font-size: 8rem;
  font-weight: bold;
  color: #197d18;
}
@keyframes ping {
  75%, 100% {
     transform: scale(4);
     opacity: 0.5;
  }
}
.animate-pingme {
  animation: ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
}


/********************************** Monitoring Screen ***********************************/
.bar {
  opacity: 0.75;
  position: relative;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
}


/****************************************** Subscription Page ***************************************/
.show-platform-list {
  top: 0;
  transition: 500ms;
}
.hide-platform-list{
  top: -100%;
  transition: 500ms;
}


/*************************************** Register page Preferred language radio & reCAPTCH ***************************/
input[type=radio]{
  width: 20px;
  height: 20px;
  accent-color: green;
}
@media (max-width: 767px) {
  input[type=radio]{
    width: 15px;
    height: 15px;
  }
}


/************************************* YouTube Video popup grid ********************************************************/
.modal {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}
.close-icon {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  color: #fff;
  font-size: 16px;
  z-index: 1000;
}
.iframe-embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
}


/************************************************* Live indicator on Header *********************************/
.live-animate-pulse {
  animation: pulse 1s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes glow {
  0% {
    /* box-shadow: 0 0 0 0px rgba(59, 130, 246, 0.7); */
    box-shadow: 0 0 0 0px rgba(183, 232, 70, 0.7);
  }
  50% {
    /* box-shadow: 0 0 0 10px rgba(59, 130, 246, 0); */
    box-shadow: 0 0 0 10px rgba(59, 130, 246, 0);
  }
  100% {
    /* box-shadow: 0 0 0 20px rgba(59, 130, 246, 0); */
    box-shadow: 0 0 0 20px rgba(59, 130, 246, 0);
  }
}

.animate-glow {
  /* animation: glow 1.5s infinite; */
  animation: glow 0.4s infinite;
}

/************************************************* Copy to clipboard icon effects *********************************/
/* .tooltip {
  position: relative;
}

.tooltip:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

.tooltip-text {
  visibility: hidden;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.75);
  color: #fff;
  border-radius: 4px;
  padding: 6px 8px;
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  transition: visibility 0s, opacity 0.3s linear;
}

.tooltip-text::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent rgba(0, 0, 0, 0.75) transparent;
} */

/* Add these styles to your CSS file */

/* Style for the tooltip */
.tooltip {
  position: relative;
}

.tooltip .copy-icon {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  opacity: 0;
  transition: opacity 0.3s;
}

.copy-icon.clicked {
  opacity: 0;
}

/* .tooltip:hover .copy-icon {
  opacity: 1;
} */

.tooltip-text {
  position: absolute;
  bottom: calc(100% + 8px);
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.75);
  color: #fff;
  border-radius: 4px;
  padding: 6px 8px;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.3s linear;
}

.tooltip:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

.tooltip-text::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent rgba(0, 0, 0, 0.75) transparent;
}

/**************************** Live dot blink ***************************/
.live-indicator {
  transform: scale(1);
  animation: live 1s infinite;
}
@keyframes live {
	0%, 100% {
		transform: scale(0.8);
	}
	50% {
		transform: scale(1.1);
	}
}

/**************************** questionnaire ***************************/
.question-container {
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}
.question-container.transitioning {
  opacity: 0;
}
.progress-bar {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.progress-segment {
  flex: 1;
  height: 4px;
  background-color: #ccc;
  /* margin-right: 5px; */
  /* margin-left: 5px; */
}
.progress-segment.filled {
  background-color: #007bff;
}
.progress-segment.current {
  background-color: #007bff;
}

/************************************ Giveaway ******************************/
@keyframes gspin {
  to {
     transform: rotate(360deg);
  }
}
.reward-spin {
  animation: gspin 3s linear infinite;
}

.animated-border {
  position: relative;
  width: 200px;
  height: 200px;
}

.animated-border:before {
  content: '';
  position: absolute;
  top: -5px;
  left: -5px;
  right: -5px;
  bottom: -5px;
  z-index: -1;
  background-image: linear-gradient(45deg, #FFA500, #FFD700, #FFA500);
  border-radius: 10px;
  animation: border-animation 5s linear infinite;
}

@keyframes border-animation {
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: 100% 100%;
  }
}

@layer utilities {
  /* Act as a border */
  .card-wrapper {
    @apply relative overflow-hidden rounded-2xl bg-gradient-to-b from-slate-700 to-slate-800;
  }

  /* Gradient */
  .card-wrapper::before {
    background: conic-gradient(
      rgba(244, 114, 182, 0.4) 0deg,
      rgba(192, 132, 252, 0.4) 0deg,
      transparent 80deg
    );

    @apply absolute left-[-25%] top-[-25%] h-[150%] w-[150%] animate-border-spin content-[''];
  }

  /* Body */
  .card-content {
    /* @apply absolute left-[1px] top-[1px] h-[calc(100%-5px)] w-[calc(100%-5px)] rounded-lg bg-gradient-to-b from-slate-800 to-slate-900; */
    @apply absolute left-[1px] top-[1px] h-[calc(100%-2px)] w-[calc(100%-2px)] rounded-lg bg-gradient-to-b from-sky-800 to-sky-400;
  }
}

@property --border-angle {
  inherits: false;
  initial-value: 0deg;
  syntax: '<angle>';
}

